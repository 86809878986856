import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './sass/index.scss';
// import './sass/home.scss';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));


